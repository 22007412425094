import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Importa los estilos del carrusel
import { useMediaQuery, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { loadImages } from '../utils/loadImages'; // Importa la función de utilidad

// Carga todas las imágenes de la carpeta 'carousel'
const images = loadImages(require.context('../assets/img/carousel', false, /\.(jpg|jpeg|png|gif)$/));

const ResponsiveCarousel = () => {
  const isLargeScreen = useMediaQuery('(min-width:1200px)');
  const isMediumScreen = useMediaQuery('(min-width:600px) and (max-width:1199px)');

  const itemsPerPage = isLargeScreen ? 3 : isMediumScreen ? 2 : 1;

  return (
    <div style={{ position: 'relative', padding: '0 40px' }}>
      <Typography
        className="custom-title"
        variant="h4"
        align="center"
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: '#EABE2a',
          fontFamily: 'Roboto, sans-serif'
        }}
      >
        Nuestros Trabajos
      </Typography>
      <Carousel 
        showArrows={true} 
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
        swipeable={true}
        dynamicHeight={true}
        emulateTouch={true}
        showStatus={false}
        showIndicators={false}
        centerMode={true}
        centerSlidePercentage={100 / itemsPerPage} // Mostrar imágenes por slide según itemsPerPage
        transitionTime={500} // Añade tiempo de transición
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button type="button" onClick={onClickHandler} title={label} style={arrowStylesPrev}>
              <ArrowBackIos />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button type="button" onClick={onClickHandler} title={label} style={arrowStylesNext}>
              <ArrowForwardIos />
            </button>
          )
        }
      >
        {images.map((src, index) => (
          <div key={index} style={{ padding: '0 10px' }}>
            <img 
              src={src} 
              alt={`carousel-item-${index}`} 
              style={{ 
                borderRadius: '30px', 
                width: '100%', 
                height: '40vh', // Añade altura 100% para asegurar que todas las imágenes tengan el mismo tamaño
                objectFit: 'cover', // Asegura que las imágenes se ajusten al contenedor sin deformarse
                marginBottom: '10rem',
                padding: '1rem'
            }} 
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

const arrowStylesPrev = {
  position: 'absolute',
  top: '50%',
  left: 0,
  transform: 'translateY(-50%)',
  background: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '50%',
  zIndex: 2,
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  padding: '10px',
};

const arrowStylesNext = {
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translateY(-50%)',
  background: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '50%',
  zIndex: 2,
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  padding: '10px',
};

export default ResponsiveCarousel;
